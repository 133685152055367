"use client";

import { buttonVariants } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/utils";
import { motion } from "framer-motion";
import { CheckCircleIcon } from "lucide-react";
import Link from "next/link";
import { Fragment, useState } from "react";
import PaddleCheckoutButton from "@/components/PaddleCheckoutButton/PaddleCheckoutButton";

type Tab = "1year" | "2year" | "lifetime";

const oneYearsPricesURL: {
    [key: string]: { key: string; productId: number; variationId: number };
} = {
    "200_site": {
        key: "200_site",
        productId: 30532,
        variationId: 30537,
    },
    "500_site": {
        key: "500_site",
        productId: 30532,
        variationId: 30597,
    },
    "1000_site": {
        key: "1000_site",
        productId: 30532,
        variationId: 30604,
    },
};

const twoYearsPricesURL: {
    [key: string]: { key: string; productId: number; variationId: number };
} = {
    "200_site": {
        key: "200_site",
        productId: 30532,
        variationId: 30595,
    },
    "500_site": {
        key: "500_site",
        productId: 30532,
        variationId: 30599,
    },
    "1000_site": {
        key: "1000_site",
        productId: 30532,
        variationId: 30600,
    },
};

const lifetimeYearsPricesURL: Record<
    string,
    { key: string; productId: number; variationId: number }
> = {
    "200_site": {
        key: "200_site",
        productId: 30532,
        variationId: 30596,
    },
    "500_site": {
        key: "500_site",
        productId: 30532,
        variationId: 30598,
    },
    "1000_site": {
        key: "1000_site",
        productId: 30532,
        variationId: 30602,
    },
};

const PricingCards = () => {
    const MotionTabTrigger = motion.create(TabsTrigger);

    const [activeTab, setActiveTab] = useState<Tab>("1year");
    const [oneYearPrice, setOneYearPrice] = useState("200_site");
    const [twoYearPrice, setTwoYearPrice] = useState("200_site");
    const [lifetimeYearPrice, setLifetimeYearPrice] = useState("200_site");

    return (
        <Tabs
            defaultValue="1year"
            className="jlt-flex jlt-flex-col jlt-items-center jlt-justify-center">
            <TabsList>
                <MotionTabTrigger
                    value="1year"
                    onClick={() => setActiveTab("1year")}
                    className="jlt-relative">
                    {activeTab === "1year" && (
                        <motion.div
                            layoutId="active-tab-indicator"
                            transition={{
                                type: "spring",
                                bounce: 0.5,
                            }}
                            className="jlt-absolute jlt-top-0 jlt-left-0 jlt-w-full jlt-h-full jlt-bg-background shadow-sm jlt-rounded-md jlt-z-10"
                        />
                    )}
                    <span className="jlt-z-20">1 Year Support</span>
                </MotionTabTrigger>
                <MotionTabTrigger
                    value="2year"
                    onClick={() => setActiveTab("2year")}
                    className="jlt-relative">
                    {activeTab === "2year" && (
                        <motion.div
                            layoutId="active-tab-indicator"
                            transition={{
                                type: "spring",
                                bounce: 0.5,
                            }}
                            className="jlt-absolute jlt-top-0 jlt-left-0 jlt-w-full jlt-h-full jlt-bg-background shadow-sm jlt-rounded-md jlt-z-10"
                        />
                    )}
                    <span className="jlt-z-20">2 Year Support</span>
                </MotionTabTrigger>
                <MotionTabTrigger
                    value="lifetime"
                    onClick={() => setActiveTab("lifetime")}
                    className="jlt-relative">
                    {activeTab === "lifetime" && (
                        <motion.div
                            layoutId="active-tab-indicator"
                            transition={{
                                type: "spring",
                                bounce: 0.5,
                            }}
                            className="jlt-absolute jlt-top-0 jlt-left-0 jlt-w-full jlt-h-full jlt-bg-background shadow-sm jlt-rounded-md jlt-z-10"
                        />
                    )}
                    <span className="jlt-z-20">Lifetime Support</span>
                </MotionTabTrigger>
            </TabsList>
            <div className="jlt-grid jlt-grid-cols-1 lg:jlt-grid-cols-3 jlt-gap-5 jlt-w-full md:jlt-gap-8 jlt-flex-wrap jlt-max-w-5xl jlt-mx-auto jlt-pt-6">
                {/* 1 year plan start */}
                <TabsContent value="1year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Starter
                            </CardTitle>
                            <CardDescription>For Freelancers, Solopreneurs</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                <del className="jlt-text-2xl jlt-text-muted-foreground">$89</del>{" "}
                                $59
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Only $11.80 per site/year
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    5 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend Access",
                                    tooltip: "Access WP Spotlight from only the backend.",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId: 30532,
                                    productVariation: 30539,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="1year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl",
                            "Business" === "Business" && "jlt-border-2 jlt-border-purple-500"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Business
                            </CardTitle>
                            <CardDescription>For small businesses and agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                <del className="jlt-text-2xl jlt-text-muted-foreground">$169</del>{" "}
                                $99
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Only $1.98 per site/year
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    50 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId: 30532,
                                    productVariation: 30536,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="1year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Agency
                            </CardTitle>
                            <CardDescription>For large organizations and Agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {[
                                    {
                                        key: "200_site",
                                        price: 199,
                                        mainPrice: 369,
                                    },
                                    {
                                        key: "500_site",
                                        price: 299,
                                        mainPrice: 599,
                                    },
                                    {
                                        key: "1000_site",
                                        price: 499,
                                        mainPrice: 1119,
                                    },
                                ].map((plan) =>
                                    plan.key === oneYearPrice ? (
                                        <Fragment key={plan.key}>
                                            <del className="jlt-text-2xl jlt-text-muted-foreground">
                                                ${plan.mainPrice}
                                            </del>{" "}
                                            ${plan.price}
                                        </Fragment>
                                    ) : null
                                )}
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Only $0.99 per site/year
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <select
                                className="jlt-w-[100%] jlt-py-[6px] jlt-px-[8px] jlt-rounded jlt-bg-[#0a0a0a] jlt-outline-none jlt-border jlt-border-[#a855f7] jlt-text-[13px] jlt-font-semibold jlt-leading-[20px] jlt-text-[#a855f7]"
                                defaultValue="200_site"
                                onChange={(e) => setOneYearPrice(e.target.value)}>
                                <option value="200_site">200 Websites</option>
                                <option value="500_site">500 Websites</option>
                                <option value="1000_site">1000 Websites</option>
                            </select>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId: oneYearsPricesURL?.[oneYearPrice]?.productId,
                                    productVariation:
                                        oneYearsPricesURL?.[oneYearPrice]?.variationId,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                {/* 1 year plan end */}

                {/* 2 year plan start */}
                <TabsContent value="2year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Starter
                            </CardTitle>
                            <CardDescription>For Freelancers, Solopreneurs</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                <del className="jlt-text-2xl jlt-text-muted-foreground">$119</del>{" "}
                                $79
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /2year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Save 33% compared to two 1-Year
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    5 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend Access",
                                    tooltip: "Access WP Spotlight from only the backend.",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId: 30532,
                                    productVariation: 30535,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="2year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl",
                            "Business" === "Business" && "jlt-border-2 jlt-border-purple-500"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Business
                            </CardTitle>
                            <CardDescription>For small businesses and agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                <del className="jlt-text-2xl jlt-text-muted-foreground">$249</del>{" "}
                                $149
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /2year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Save 25% compared to two 1-Year
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />{" "}
                                    50 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId: 30532,
                                    productVariation: 30593,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="2year">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Agency
                            </CardTitle>
                            <CardDescription>For large organizations and Agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {[
                                    {
                                        key: "200_site",
                                        price: 249,
                                        mainPrice: 459,
                                    },
                                    {
                                        key: "500_site",
                                        price: 449,
                                        mainPrice: 899,
                                    },
                                    {
                                        key: "1000_site",
                                        price: 699,
                                        mainPrice: 1559,
                                    },
                                ].map((plan) =>
                                    plan.key === twoYearPrice ? (
                                        <Fragment key={plan.key}>
                                            <del className="jlt-text-2xl jlt-text-muted-foreground">
                                                ${plan.mainPrice}
                                            </del>{" "}
                                            ${plan.price}
                                        </Fragment>
                                    ) : null
                                )}
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /2year
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Save 25% compared to two 1-Year
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <select
                                className="jlt-w-[100%] jlt-py-[6px] jlt-px-[8px] jlt-rounded jlt-bg-[#0a0a0a] jlt-outline-none jlt-border jlt-border-[#a855f7] jlt-text-[13px] jlt-font-semibold jlt-leading-[20px] jlt-text-[#a855f7]"
                                defaultValue="200_site"
                                onChange={(e) => setTwoYearPrice(e.target.value)}>
                                <option value="200_site">200 Websites</option>
                                <option value="500_site">500 Websites</option>
                                <option value="1000_site">1000 Websites</option>
                            </select>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId: twoYearsPricesURL?.[twoYearPrice]?.productId,
                                    productVariation:
                                        twoYearsPricesURL?.[twoYearPrice]?.variationId,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                {/* 2 year plan end */}

                {/* lifetime plan start */}
                <TabsContent value="lifetime">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Starter
                            </CardTitle>
                            <CardDescription>For Freelancers, Solopreneurs</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                <del className="jlt-text-2xl jlt-text-muted-foreground">$229</del>{" "}
                                $149
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /Lifetime
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Pay once and be done with it.
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    5 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend Access",
                                    tooltip: "Access WP Spotlight from only the backend.",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId: 30532,
                                    productVariation: 30540,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="lifetime">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl",
                            "Business" === "Business" && "jlt-border-2 jlt-border-purple-500"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Business
                            </CardTitle>
                            <CardDescription>For small businesses and agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                <del className="jlt-text-2xl jlt-text-muted-foreground">$499</del>{" "}
                                $299
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /Lifetime
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Pay once and never think about it.
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <div className="jlt-flex jlt-justify-between jlt-items-center jlt-select-none">
                                <span className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    50 Websites
                                </span>
                            </div>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId: 30532,
                                    productVariation: 30594,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                <TabsContent value="lifetime">
                    <Card
                        className={cn(
                            "jlt-flex jlt-flex-col jlt-w-full jlt-border-border jlt-rounded-xl"
                        )}>
                        <CardHeader
                            className={cn(
                                "jlt-border-b jlt-border-border",
                                "Business" === "Business"
                                    ? "jlt-bg-purple-500/[0.07]"
                                    : "jlt-bg-foreground/[0.03]"
                            )}>
                            <CardTitle
                                className={cn(
                                    "Business" !== "Business" && "jlt-text-muted-foreground",
                                    "jlt-text-2xl jlt-font-medium"
                                )}>
                                Agency
                            </CardTitle>
                            <CardDescription>For large organizations and Agencies</CardDescription>
                            <h5 className="!jlt-mt-5 jlt-text-3xl jlt-font-semibold">
                                {[
                                    {
                                        key: "200_site",
                                        price: 499,
                                        mainPrice: 909,
                                    },
                                    {
                                        key: "500_site",
                                        price: 999,
                                        mainPrice: 1999,
                                    },
                                    {
                                        key: "1000_site",
                                        price: 1399,
                                        mainPrice: 3339,
                                    },
                                ].map((plan) =>
                                    plan.key === lifetimeYearPrice ? (
                                        <Fragment key={plan.key}>
                                            <del className="jlt-text-2xl jlt-text-muted-foreground">
                                                ${plan.mainPrice}
                                            </del>{" "}
                                            ${plan.price}
                                        </Fragment>
                                    ) : null
                                )}
                                <span className="jlt-text-base jlt-text-muted-foreground jlt-font-normal">
                                    /Lifetime
                                </span>
                            </h5>
                            <span className="jlt-text-sm jlt-text-[#a855f7]">
                                Pay once for ever.
                            </span>
                        </CardHeader>
                        <CardContent className="jlt-pt-6 jlt-space-y-4">
                            <select
                                className="jlt-w-[100%] jlt-py-[6px] jlt-px-[8px] jlt-rounded jlt-bg-[#0a0a0a] jlt-outline-none jlt-border jlt-border-[#a855f7] jlt-text-[13px] jlt-font-semibold jlt-leading-[20px] jlt-text-[#a855f7]"
                                defaultValue="200_site"
                                onChange={(e) => setLifetimeYearPrice(e.target.value)}>
                                <option value="200_site">200 Websites</option>
                                <option value="500_site">500 Websites</option>
                                <option value="1000_site">1000 Websites</option>
                            </select>
                            {[
                                {
                                    text: "Backend & frontend access",
                                    tooltip:
                                        "Access WP Spotlight from both the backend and frontend",
                                },
                                {
                                    text: "Manager users from search",
                                    tooltip:
                                        "Quickly search and manage user profiles directly from search",
                                },
                                {
                                    text: "Manage plugins from search",
                                    tooltip:
                                        "Activate, deactivate, and update plugins without leaving search",
                                },
                                {
                                    text: "Manage content from search",
                                    tooltip:
                                        "Find and edit posts, pages, and custom content instantly.",
                                },
                                {
                                    text: "Manage media from search",
                                    tooltip:
                                        "Upload, edit, and insert media files straight from search.",
                                },
                                {
                                    text: "Multisite switching",
                                    tooltip: "Easily switch between sites in multisite setups.",
                                },
                                {
                                    text: "Custom keyboard shortcuts ",
                                    tooltip:
                                        "Assign custom key binds with up to 4 key combinations.",
                                },
                            ].map((feature) => (
                                <div
                                    key={feature.text}
                                    className="jlt-flex jlt-items-center jlt-gap-2">
                                    <CheckCircleIcon className="jlt-text-purple-500 jlt-w-4 jlt-h-4" />
                                    <TooltipProvider>
                                        <Tooltip delayDuration={0}>
                                            <TooltipTrigger asChild>
                                                <p
                                                    className={cn(
                                                        "jlt-border-b !jlt-border-dashed jlt-border-border jlt-cursor-pointer"
                                                    )}>
                                                    {feature.text}
                                                </p>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{feature.tooltip}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            ))}
                        </CardContent>
                        <CardFooter className="jlt-w-full jlt-mt-auto">
                            <PaddleCheckoutButton
                                attributes={{
                                    btnText: "Buy Now",
                                    productId:
                                        lifetimeYearsPricesURL?.[lifetimeYearPrice]?.productId,
                                    productVariation:
                                        lifetimeYearsPricesURL?.[lifetimeYearPrice]?.variationId,
                                }}
                            />
                        </CardFooter>
                    </Card>
                </TabsContent>
                {/* lifetime plan end */}
            </div>
        </Tabs>
    );
};

export default PricingCards;
