"use client";

import { Fragment, useEffect, useState } from "react";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import axios from "axios";
import Loader from "@litonarefin/components/Loader";

const PaddleCheckoutButton = ({ attributes }) => {
    const [paddle, setPaddle] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const { btnText, productId, productVariation } = attributes;

    // const allAOS = getAOSAnimation(attributes);

    /**
     * Handle Checkout
     */
    const handleCheckout = async () => {
        setIsLoading(true);
        const paddleProductId = await getPaddleProductId(productId, productVariation);

        if (paddleProductId) {
            paddle?.Checkout.open({
                items: [
                    {
                        priceId: paddleProductId,
                        quantity: 1,
                    },
                ],
            });
        }
    };

    const getPaddleProductId = async (productId, variationId) => {
        const res = await fetch(
            `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/product?product_id=${productId}&variation_id=${variationId}`,
            {
                cache: "no-cache",
            }
        );
        const { data } = await res.json();

        const planId = data?.variations_data?.variations?.[variationId]?.plan_id;

        if (planId) {
            return planId;
        }
    };

    /**
     * Handle Paymet Action
     * @param {*} event
     * @returns
     */
    const actionHandle = async (event) => {
        if (!event.name) {
            return setIsLoading(false);
        }

        if (event?.type === "checkout.error") {
            setIsLoading(false);
        }

        switch (event.name) {
            case "checkout.loaded":
                setIsLoading(false);
                break;
            case "checkout.customer.created":
                const existingUserId = await checkExistingUser(event.data.customer.email);

                if (existingUserId?.result?.userId) {
                    sessionStorage.setItem("jlt_uf", existingUserId?.result?.userId);

                    const order = await createOrder(existingUserId?.result?.userId, event.data);
                    if (order?.order_id) {
                        sessionStorage.setItem("jlt_oid", order?.order_id);
                    }
                } else {
                    const user = await createUser(event.data.customer.email);
                    sessionStorage.setItem("jlt_uf", user?.result?.user_id);

                    const order = await createOrder(user?.result?.user_id, event.data);
                    if (order?.order_id) {
                        sessionStorage.setItem("jlt_oid", order?.order_id);
                    }
                }
                break;
            case "checkout.payment.selected":
                break;
            case "checkout.discount.applied":
                // const userId = sessionStorage.getItem("jlt_uf");
                const updateOrder = await updateOrderStatus(
                    "pending",
                    "",
                    event.data.discount.code
                );
                break;
            case "checkout.discount.removed":
                // const removeUpdateOrder = await updateOrderStatus("pending", "", "");
                break;
            case "checkout.items.updated":
                // const userId = sessionStorage.getItem("jlt_uf");

                break;
            case "checkout.payment.initiated":
                break;
            case "checkout.completed":
                await updateOrderStatus("completed", event?.data?.transaction_id, "");
                break;
            default:
                setIsLoading(false);
                break;
        }
    };

    /**
     * Check Existing User
     * @param {*} email
     */
    const checkExistingUser = async (email) => {
        let { data: checkUserExists } = await axios.get(
            `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/users`,
            { params: { email: email } }
        );

        return checkUserExists;
    };

    /**
     * Create User
     * @param {*} email
     * @param {*} password
     */
    const createUser = async (email, password = "jlt_1234") => {
        let { data: registered } = await axios.post(`/api/auth/register`, {
            first_name: "",
            last_name: "",
            user_email: email,
            user_pass: password,
        });

        return registered;
    };

    /**
     * Create Order
     */
    const createOrder = async (userId, data) => {
        const billing = {
            first_name: "",
            last_name: "",
            email: "",
            company: "",
            country: "",
            city: "",
            postCode: "",
            password: "",
        };

        const orderData = {
            user_id: userId,
            order_status: "completed",
            total: data.totals.total,
            billing: billing,
            shipping: billing,
            line_items: [
                {
                    variation_id: productVariation,
                    product_id: productId,
                    quantity: 1,
                },
            ],
            payment_method: "sparkle_paddle_checkout_overlay",
            payment_method_title: "Sparkle Paddle Overlay",
            site_source: process.env.NEXT_PUBLIC_SOURCE_SITE,
        };

        const { data: orderCreateData } = await axios.post(
            `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/orders/create`,
            {
                ...orderData,
                // coupon_code: coupon?.code || "",
                pay_link: JSON.stringify({}),
                gutenberg: true,
                transaction_id: data.transaction_id,
            }
        );

        return orderCreateData;
    };

    /**
     * Update Order Status
     * @param {*} order_id
     * @param {*} trans_id
     * @param {*} discount
     */
    const updateOrderStatus = async (status, trans_id = "", coupon_code = "") => {
        const orderId = sessionStorage.getItem("jlt_oid");

        let { data } = await axios.put(
            `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/orders/paddle-update`,
            {
                gutenberg: true,
                body: {
                    order_id: orderId,
                    trans_id: trans_id,
                    coupon_code: coupon_code,
                    status: status,
                },
            }
        );

        if (data?.success) {
            // sessionStorage.removeItem("jlt_oid");
        }
    };

    useEffect(() => {
        // Initialization Paddle
        initializePaddle({
            environment: process.env.NEXT_PUBLIC_PADDLE_MODE || "production",
            token: process.env.NEXT_PUBLIC_PADDLE_VENDOR_AUTH_CODE,
            eventCallback: actionHandle,
        }).then((paddleInstance) => {
            if (paddleInstance) {
                setPaddle(paddleInstance);
            }
        });
    }, []);

    return (
        <Fragment>
            <button
                className="jlt-w-full jlt-inline-flex jlt-gap-2 jlt-items-center jlt-justify-center jlt-whitespace-nowrap jlt-rounded-md jlt-text-sm jlt-font-medium jlt-ring-offset-background jlt-transition-colors focus-visible:jlt-outline-none focus-visible:jlt-ring-2 focus-visible:jlt-ring-ring focus-visible:jlt-ring-offset-2 disabled:jlt-pointer-events-none disabled:jlt-opacity-50 active:jlt-scale-95 jlt-transition-all jlt-bg-primary jlt-text-primary-foreground hover:jlt-bg-primary/90 jlt-primary jlt-h-9 jlt-px-4 jlt-py-2 jlt-bg-purple-500 hover:jlt-bg-purple-500/80 jlt-text-white"
                onClick={handleCheckout}
                disabled={isLoading ? true : false}>
                {isLoading ? (
                    <Loader
                        type="button-loader"
                        styles={{
                            width: "24px",
                            height: "24px",
                        }}
                    />
                ) : null}
                {btnText || "Button Text"}
            </button>
        </Fragment>
    );
};

export default PaddleCheckoutButton;
