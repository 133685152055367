import React from "react";

interface Props {
    title: string;
}

const MagicBadge = ({ title }: Props) => {
    return (
        <div className="jlt-relative jlt-inline-flex jlt-h-8 jlt-overflow-hidden jlt-rounded-full jlt-p-[1.5px] focus:jlt-outline-none jlt-select-none">
            <span className="jlt-absolute jlt-inset-[-1000%] jlt-animate-[spin_3s_linear_infinite] jlt-bg-[conic-gradient(from_90deg_at_50%_50%,#6d28d9_0%,#d8b4fe_50%,#6d28d9_100%)]" />
            <span className="jlt-inline-flex jlt-h-full jlt-w-full jlt-cursor-pointer jlt-items-center jlt-justify-center jlt-rounded-full jlt-bg-slate-950 jlt-px-4 jlt-py-1 jlt-text-sm jlt-font-medium jlt-text-white jlt-backdrop-blur-3xl">
                {title}
            </span>
        </div>
    );
};

export default MagicBadge;
