"use client";

import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { cn } from "@/utils";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
            "jlt-z-50 jlt-overflow-hidden jlt-rounded-md jlt-border jlt-border-border jlt-bg-popover jlt-px-3 jlt-py-1.5 jlt-text-sm jlt-text-popover-foreground jlt-shadow-md jlt-animate-in jlt-fade-in-0 jlt-zoom-in-95 data-[state=closed]:jlt-animate-out data-[state=closed]:jlt-fade-out-0 data-[state=closed]:jlt-zoom-out-95 data-[side=bottom]:jlt-slide-in-from-top-2 data-[side=left]:jlt-slide-in-from-right-2 data-[side=right]:jlt-slide-in-from-left-2 data-[side=top]:jlt-slide-in-from-bottom-2",
            className
        )}
        {...props}
    />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
